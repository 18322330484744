<template>
  <v-container>
    <v-card class="bg-card rounded-xl py-5 mt-5">
      <h4 class="text-center text-h4 mb-5">Impresoras</h4>

      <div
        class="
          mx-5 mx-md-16
          px-md-16
          d-flex
          flex-column flex-md-row
          align-center align-md-baseline
        "
      >
        <div style="width: 250px">
          <v-text-field
            outlined
            dense
            solo
            placeholder="Buscar"
            class="rounded-lg"
            prepend-inner-icon="fa-search"
            v-model="search"
          ></v-text-field>
        </div>

        <v-spacer />

        <div v-if="roleUser == 1">
          <v-btn
            outlined
            color="primary"
            class="mr-md-2 my-2 my-md-0 rounded-lg"
            @click="dialog = true"
          >
            <v-icon>fa-plus</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold"> Crear </span>
          </v-btn>

          <v-btn
            outlined
            color="primary"
            class="my-2 my-md-0 rounded-lg"
            v-if="active"
            @click="active = false"
          >
            <v-icon>fa-archive</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">
              Eliminadas
            </span>
          </v-btn>

          <v-btn
            outlined
            color="primary"
            class="my-2 my-md-0 rounded-lg"
            v-else
            @click="active = true"
          >
            <v-icon>fa-check</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold"> Activas </span>
          </v-btn>
        </div>
      </div>

      <div v-if="roleUser == 1">
        <active v-if="active" :search="search" :newItem="updateList" />
        <inactive v-else :search="search" />
      </div>

      <active-cli v-else />
    </v-card>

    <form-dialog
      :printer="printer"
      :edit="edit"
      :dialog="dialog"
      @dismiss="newItem"
    />

    <alerts-cli />
  </v-container>
</template>

<script>
import AlertsCli from '@/components/Alerts.vue'
import Active from './Active.vue'
import Inactive from './Inactive.vue'
import FormDialog from './FormDialog.vue'
import ActiveCli from './ActiveCli.vue'
export default {
  components: {
    AlertsCli,
    Active,
    Inactive,
    FormDialog,
    ActiveCli,
  },
  name: 'Printers',
  data: () => ({
    active: true,
    search: '',
    edit: false,
    printer: null,
    dialog: false,
    updateList: false,
  }),
  computed: {
    roleUser: function () {
      return this.$store.state.auth.user?.roleId || ''
    },
  },
  methods: {
    newItem: function () {
      this.dialog = false
      this.updateList = true

      setTimeout(() => {
        this.updateList = false
      }, 10)
    },
  },
}
</script>
