<template>
  <div>
    <printers />
  </div>
</template>

<script>
import Printers from '@/components/Devices/Printers/Printers.vue'

export default {
  components: { Printers },
  name: 'Printer',
}
</script>
